import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import ScreenHeader from "../components/ScreenHeader";
import * as AppActions from "../redux/actions/app.actions";
import * as OrderActions from "../redux/actions/order.actions";
import ICreateOrderParams from "@sellout/models/.dist/interfaces/ICreateOrderParams";
import { Dropdown } from "@sellout/ui";
import { PurchasePortalState } from "../redux/store";
import { IEventGraphQL } from "@sellout/models/.dist/interfaces/IEvent";
import { ISeasonGraphQL } from "@sellout/models/.dist/interfaces/ISeason";
import PaymentOverview from "../components/PaymentOverview";
import { Spacer } from "./CreateWaitListInfo";


const Container = styled.div`
  position: relative;
  top: -50px;
`;

const Content = styled.div`
  margin: 24px 0 0;
 
`;
const Scrollcontent = styled.div`
max-height: 195px;
overflow-y: auto;
padding: 0 24px;

`;
const DropdownWrapper = styled.div`
padding: 0 24px;
`;
type PaymentMethodProps = { event?: IEventGraphQL; season?: ISeasonGraphQL };

const PaymentMethod: React.FC<PaymentMethodProps> = ({ event, season }) => {

  
  /** Actions **/
  const {
    order: {
      createOrderParams: { paymentMethodType },
    },
  } = useSelector((state: PurchasePortalState) => state);
  const dispatch = useDispatch();
  const setOrder = (params: Partial<ICreateOrderParams>) => {
    dispatch(OrderActions.setCreateOrderParams(params));
    dispatch(AppActions.navigateForward());
  };
  /** Render **/

  const handleOnChange = (selectedValue) => {
    dispatch(
      OrderActions.setCreateOrderParams({
        paymentMethodType: selectedValue,
        
      })
    );
  };
  return (
    <Container>
      <ScreenHeader title="Payment overview" />
      <Content>
        <Scrollcontent className="scroll-show">
        <PaymentOverview event={event} season={season}/>
        </Scrollcontent>
        <Spacer></Spacer>
        <DropdownWrapper className={paymentMethodType=="Cash" || paymentMethodType=="Check" ? "payment-method-height" :"payment-method-dropdown"} >
        <Dropdown
          value={paymentMethodType}
          items={[
            { text: "Card Reader", value: "Card Reader" },
            { text: "Manual card entry", value: "Card Entry" },
            { text: "Cash", value: "Cash" },
            { text: "Check", value: "Check" },
          ]}
          onChange={handleOnChange}
          label="Select payment method"
          placeholder="Select payment method"
          width="100%"
        />
        </DropdownWrapper>
      </Content>
    </Container>
  );
};

export default PaymentMethod;
