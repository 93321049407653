import { ApolloClient, from, } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import errorLink from './links/errorLink';
import requestLink from './links/requestLink';
import uploadLink from './links/uploadLink';
import debounceLink from '@sellout/models/.dist/graphql/links/debounceLink';

const link = from([
  requestLink,
  errorLink,
  debounceLink as any ,
  uploadLink 
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default client;
