import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { PurchasePortalState } from "../redux/store";
import { Colors } from "@sellout/ui/build/Colors";
import * as Polished from "polished";
import StripeElements from "./../containers/StripeElements";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { StripeElementStyle } from "@stripe/stripe-js";
import { EPurchasePortalModes } from "@sellout/models/.dist/enums/EPurchasePortalModes";
import { ErrorKeyEnum } from "../redux/reducers/app.reducer";
import { Input } from "@sellout/ui";
import { InputSizes } from "@sellout/ui/build/components/Input";
import * as OrderActions from "../redux/actions/order.actions";


const Container = styled.div`
 position: relative;
 top: -50px;
  height: 300px;
  background-color: ${Colors.SurfaceTertiary};
  border-radius: 15px;
  overflow: hidden;
  padding-bottom: 66px;
  border: 1px solid #453d3d;
  margin: 20px;

  .Element {
    margin: 0;
    max-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    text-align: left;
    line-height: 1.6rem;
    background: transparent;
    border-radius: 12px;
    transition: box-shadow 0.1s ease, border-color 0.1s ease;
    box-shadow: none;
    font-size: 16px;
    padding-top: 18.4px;
    padding-left: 16px;
    height: 56px;
    box-sizing: border-box;
    border:1px solid ${Colors.SecondaryStroke};
    &.StripeElement--invalid {
      border-color:${Colors.Red};
    }
  }
`;

const Form = styled.form``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const Spacer = styled.div`
  width: 10px;
`;

type ElementContainerProps = {
  focused: boolean;
};

const ElementContainer = styled.div<ElementContainerProps>`
  position: relative;
  flex: 1;

  .Element {
    border: 1px solid
      ${(props) => {
        if (props.focused) return Colors.Grey4;
        return Colors.SecondaryStroke;
      }};

    &:hover {
      border: 1px solid
        ${(props) => {
          if (props.focused) return Colors.Grey4;
          return Polished.darken(0.05, Colors.Grey5);
        }};
    }
  }
`;

const Content = styled.div`
  // margin: 24px 0 0;
  padding: 24px;
`;
const FormGroup = styled.div`
  flex: 1;
`;
export const StarIcon = styled.sup`
  color: ${Colors.Red};
  font-size: 16px;
  vertical-align: baseline;
`;
const CustomLabel = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: 0 0 8px;
  color: ${Colors.HeadingSecondary};
`;

const Text = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${Colors.Grey2};
  margin-bottom: 30px;
`;

const ErrorMessage = styled.div`
  color: red;
  padding: 4px;
`;

enum ElementsEnum {
  CardNumber = "CardNumber",
  CVC = "CVC",
  ExpDate = "ExpDate",
  PostalCode = "Zip Code",
}

type AddPaymentProps = {};

const AddPayment: React.FC<AddPaymentProps> = () => {
  /** State **/
  const [focused, setFocus] = React.useState<ElementsEnum | null>(null);
  const {
    app: { mode, errors },
    order:{zipCode}
  } = useSelector((state: PurchasePortalState) => state);
  const isBoxOffice = mode === EPurchasePortalModes.BoxOffice;
  const errorMsg: any = errors[ErrorKeyEnum.PaymentCardError];
  /** Render **/
  const style: StripeElementStyle = {
    base: {
      fontSize: "16px",
      color: Colors.HeadingSecondary,
      fontSmoothing: "antialiased",
      fontFamily: "neue-haas-grotesk-display",
      "::placeholder": {
        color: Colors.SupportTertiary,
      },
      ":focus": {},
    },
    invalid: {
      color: Colors.Red,
    },
  };

  const isFocused = (field: ElementsEnum) => field === focused;
  useEffect(() => {
    dispatch(OrderActions.setZipCodePaymentMethod(""));
  }, []);
  let zipCodeValidation = zipCode.length == 5 ? true :false
   /** Actions **/
   const dispatch = useDispatch();
  const setZipCodePaymentMethod = (postalCode: string) =>
    dispatch(OrderActions.setZipCodePaymentMethod(postalCode));
  return (
    <Container>
      <Content>
        <CustomLabel>
          Card Number <StarIcon>*</StarIcon>
        </CustomLabel>
        <Form>
          <Row>
            <ElementContainer focused={isFocused(ElementsEnum.CardNumber)}>
              <CardNumberElement
                className="Element"
                options={{ style, placeholder: "Card Number" }}
                onFocus={() => setFocus(ElementsEnum.CardNumber)}
                onBlur={() => setFocus(null)}
              />
            </ElementContainer>
          </Row>
          <Row>
            <FormGroup>
              <CustomLabel>
                Exp. Date <StarIcon>*</StarIcon>
              </CustomLabel>
              <ElementContainer focused={isFocused(ElementsEnum.ExpDate)}>
                <CardExpiryElement
                  className="Element"
                  options={{ style, placeholder: "Exp. Date" }}
                  onFocus={() => setFocus(ElementsEnum.ExpDate)}
                  onBlur={() => setFocus(null)}
                />
              </ElementContainer>
            </FormGroup>
            <Spacer />
            <FormGroup>
              <CustomLabel>
                Security Code <StarIcon>*</StarIcon>
              </CustomLabel>
              <ElementContainer focused={isFocused(ElementsEnum.CVC)}>
                <CardCvcElement
                  className="Element"
                  options={{ style, placeholder: "CVC" }}
                  onFocus={() => setFocus(ElementsEnum.CVC)}
                  onBlur={() => setFocus(null)}
                />
              </ElementContainer>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <CustomLabel>
                Zip Code <StarIcon>*</StarIcon>
              </CustomLabel>
              <ElementContainer focused={isFocused(ElementsEnum.PostalCode)}>
                <Input
                  type="number"
                  maxLength={5}
                  size={InputSizes.CustomInputHeight}
                  placeholder="Zip Code"
                  onFocus={() => setFocus(ElementsEnum.PostalCode)}
                  onBlur={() => setFocus(null)}
                  value={zipCode}
                  bgColor={Colors.SurfaceTertiary}
                  placeholderTextColor={`${Colors.SupportTertiary}`}
                  onChange={(event: React.FormEvent<HTMLInputElement>) => {
                    setZipCodePaymentMethod(event.currentTarget.value)
                  }}
                  labelColor={Colors.HeadingSecondary}
                  onFocusedColor={true}
                  mediaDesktopFontSize={"1.6rem"}
                  validationError={zipCode.length > 0 && zipCode.length < 5 ||(zipCodeValidation ?"":errors.PaymentCardError?.length)} 
                  validationDarkThemeBorderColor={zipCode.length > 0 && zipCode.length < 5 || !!errors?.PaymentCardError?.length} 
                  maxLengthDisabled={true}
                  disableValidationErrorBox={true}
                  
                />
              </ElementContainer>
            </FormGroup>
          </Row>
        </Form>

        <ErrorMessage>{errorMsg}</ErrorMessage>
      </Content>
    </Container>
  );
};

const WrappedAddPayment = (props: AddPaymentProps) => (
  <StripeElements useConnectedAccount={false}>
    <AddPayment {...props} />
  </StripeElements>
);

export default WrappedAddPayment;
