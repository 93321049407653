import React from "react";
import {
  PriceText,
  PriceTextBold,
  Row,
  Title,
  TitleBold,
} from "../screens/ConfirmOrder";
import { Flex } from "@sellout/ui";
import * as Price from "@sellout/utils/.dist/price";
import { IEventGraphQL } from "@sellout/models/.dist/interfaces/IEvent";
import { useSelector } from "react-redux";
import { PurchasePortalState } from "../redux/store";
import PaymentUtil from "@sellout/models/.dist/utils/PaymentUtil";
import EventUtil from "@sellout/models/.dist/utils/EventUtil";
import { ISeasonGraphQL } from "@sellout/models/.dist/interfaces/ISeason";
import IFee, {
  FeeAppliedByEnum,
  FeeAppliedToEnum,
  FeeFiltersEnum,
  FeeTypeEnum,
} from "@sellout/models/.dist/interfaces/IFee";
import IEventPromotion, {
  EventPromotionDiscountTypeEnum,
  EventPromotionAppliesToEnum,
} from "@sellout/models/.dist/interfaces/IEventPromotion";
import {
  ICreateOrderTicketParams,
} from "@sellout/models/.dist/interfaces/ICreateOrderParams";

type PaymentOverviewProps = { event?: IEventGraphQL; season?: ISeasonGraphQL };

enum FeeName {
  FeeName = "Sales tax",
}
const PaymentOverview: React.FC<PaymentOverviewProps> = ({ event, season }) => {
  const { app, order } = useSelector((state: PurchasePortalState) => state);
  const {
    appliedDiscount,
    createOrderParams: {
      tickets,
      upgrades,
      discountCode,
      paymentMethodType,
      discountAmount,
    },
  } = order;
  const { isComplimentary } = app;
  const fees = event ? event?.fees : season?.fees;
  const ticketTotal = tickets?.reduce(
    (cur, ticket) => cur + parseFloat(ticket.values as string),
    0
  );
  const totalParams: any = {
    tickets,
    upgrades,
    fees: event ? event?.fees : season?.fees,
    paymentMethodType,
    promotions: appliedDiscount as any,
  };

  const promoterFee =
    EventUtil.isRSVP(event as IEventGraphQL) || isComplimentary
      ? 0
      : CalculatePromoterFeeList({
          tickets,
          fees,
          promotions: event ? event?.promotions : season?.promotions,
          upgrades,
          discountCode,
          paymentMethodType,
        });
  const subTotal =
    EventUtil.isRSVP(event as IEventGraphQL) || isComplimentary
      ? ticketTotal
      : PaymentUtil.calculatePaymentTotal(totalParams).subTotal;
  const salesTaxFees = fees?.find((f) => f.name == "Sales tax");

  const salesTax: any = EventUtil.isRSVP(event as IEventGraphQL) || isComplimentary? 0 : salesTaxFees ? (PaymentUtil.calculatePaymentTotal(totalParams).orderSubtotal * salesTaxFees?.value) /100 : 0;
    
  const selloutFees =
  EventUtil.isRSVP(event as IEventGraphQL) || isComplimentary
  ? 0
    :PaymentUtil.calculatePaymentTotal(totalParams).selloutFees;

  const processingFees =
  EventUtil.isRSVP(event as IEventGraphQL) || isComplimentary
  ? 0
    :PaymentUtil.calculatePaymentTotal(totalParams).stripeFees;

  const total =
    EventUtil.isRSVP(event as IEventGraphQL) || isComplimentary
      ? ticketTotal
      : PaymentUtil.calculatePaymentTotal(totalParams).total;
  return (
    <>
      {discountCode && (
        <Row>
          <Flex justify="space-between">
            <Flex direction="row">
              <Title>Discount</Title>
            </Flex>
            <PriceText>${Price.output(discountAmount, true)}</PriceText>
          </Flex>
        </Row>
      )}
      <Row>
        <Flex justify="space-between">
          <Flex direction="row">
            <Title>Subtotal</Title>
          </Flex>
          <PriceText>${Price.output(subTotal, true)}</PriceText>
        </Flex>
      </Row>
      {event?.taxDeduction && salesTax > 0 && (
        <Row>
          <Flex justify="space-between">
            <Flex direction="row">
              <Title>Sales Tax</Title>
            </Flex>
            <PriceText>${Price.output(salesTax, true)}</PriceText>
          </Flex>
        </Row>
      )}

      {promoterFee ==0 ||promoterFee?.map((item) => (   
        <Row>
          <Flex justify="space-between">
            <>
              <Flex direction="row">
                <Title> {item.name}</Title>
              </Flex>
              <PriceText>${Price.output(item.amount, true )}</PriceText>
            </>
          </Flex>
        </Row>
      ))}
      {selloutFees > 0 && (
        <Row>
          <Flex justify="space-between">
            <Flex direction="row">
              <Title>Service Fee</Title>
            </Flex>
            <PriceText>${Price.output(selloutFees, true)}</PriceText>
          </Flex>
        </Row>
      )}
      {processingFees > 0 && (
        <Row>
          <Flex justify="space-between">
            <Flex direction="row">
              <Title>Processing Fees</Title>
            </Flex>
            <PriceText>${Price.output(processingFees, true)}</PriceText>
          </Flex>
        </Row>
      )}
      <Row>
        <Flex justify="space-between">
          <Flex direction="row">
            <TitleBold>Total</TitleBold>
          </Flex>
          <PriceTextBold>${Price.output(total, true)}</PriceTextBold>
        </Flex>
      </Row>
      {/* <Border /> */}
    </>
  );
};

export const CalculatePromoterFeeList = (params: {
  tickets;
  fees;
  promotions;
  upgrades;
  discountCode;
  paymentMethodType;
}) => {
  let tickets: any = params.tickets;
  let upgrades: any = params.upgrades;
  let promoterFees: any = [];

  params.fees = params.fees.filter((fee: IFee) => {
    if (!fee?.paymentMethods || fee?.paymentMethods?.length == 0) {
      return true;
    } else if (
      fee?.paymentMethods?.includes(params?.paymentMethodType as any)
    ) {
      return true;
    } else {
      return false;
    }
  });

  params.fees.forEach((item) => {
    const newItem = { ...item, amount: 0 };
    if (newItem.appliedBy === FeeAppliedByEnum.Organization) {
      if (newItem.name === "Sales tax") {
      } else {
        promoterFees.push(newItem);
      }
    }
  });

  const promotionsCode = params?.promotions?.filter((promo) => {
    const promoCode = promo?.code?.toLowerCase();
    const discountCode =
      typeof params?.discountCode === "string"
        ? params.discountCode.toLowerCase()
        : "";
    return promoCode === discountCode;
  });

  if (promotionsCode.length != 0) {
    tickets = tickets.map((ticket) => {
      if (
        promotionsCode &&
        promotionsCode[0].discountType ===
          EventPromotionDiscountTypeEnum.Flat &&
        promotionsCode[0].appliesTo === EventPromotionAppliesToEnum.PerTicket
      ) {
        if (promotionsCode[0]?.ticketTypeIds.length == 0) {
          const discountValue = Number(promotionsCode[0].discountValue);
          const originalPrice = ticket.price;
          const discountedPrice = originalPrice - discountValue;
          return {
            ...ticket,
            price: discountedPrice,
          };
        } else {
          if (
            promotionsCode &&
            promotionsCode[0].ticketTypeIds.includes(ticket?.ticketTypeId)
          ) {
            const discountValue = Number(promotionsCode[0].discountValue);
            const originalPrice = ticket.price;
            const discountedPrice = originalPrice - discountValue;
            return {
              ...ticket,
              price: discountedPrice,
            };
          } else {
            const originalPrice = ticket.price;
            return {
              ...ticket,
              price: originalPrice,
            };
          }
        }
      }

      if (
        promotionsCode &&
        promotionsCode[0].discountType ===
          EventPromotionDiscountTypeEnum.Percent &&
        promotionsCode[0].appliesTo === EventPromotionAppliesToEnum.PerTicket
      ) {
        if (promotionsCode[0]?.ticketTypeIds.length == 0) {
          const discountValue = Number(promotionsCode[0].discountValue);
          const percentTicketValue =
            (ticket.origionalPrice * discountValue) / 100;
          const originalPrice = ticket.price;
          const discountPercentPrice = originalPrice - percentTicketValue;

          return {
            ...ticket,
            price: discountPercentPrice,
          };
        } else {
          if (promotionsCode[0]?.ticketTypeIds.includes(ticket?.ticketTypeId)) {
            const discountValue = Number(promotionsCode[0].discountValue);
            const percentTicketValue =
              (ticket.origionalPrice * discountValue) / 100;
            const originalPrice = ticket.price;
            const discountPercentPrice = originalPrice - percentTicketValue;

            return {
              ...ticket,
              price: discountPercentPrice,
            };
          } else {
            const originalPrice = ticket.price;
            return {
              ...ticket,
              price: originalPrice,
            };
          }
        }
      }
      return ticket;
    });
  }

  const discountFeeFlatPerOrderAmt = promotionsCode?.reduce(
    (cur: number, promotion: any) => {
      if (
        promotionsCode?.length &&
        promotionsCode[0].discountType ===
          EventPromotionDiscountTypeEnum.Flat &&
        promotionsCode[0].appliesTo === EventPromotionAppliesToEnum.PerOrder
      ) {
        const value = cur + promotion.discountValue;
        return value;
      }
      return cur;
    },
    0
  );
  const ticketTotal = tickets.reduce((cur, ticket) => cur + ticket.price, 0);
  const upgradeTotal = upgrades.reduce(
    (cur, upgrade) => cur + upgrade.price,
    0
  );
  const totalTicketUpgrade = ticketTotal + upgradeTotal;

  const discountFeePercentPerOrderAmt = promotionsCode?.reduce(
    (cur: number, promotion: any) => {
      if (
        promotionsCode?.length &&
        promotionsCode[0].discountType ===
          EventPromotionDiscountTypeEnum.Percent &&
        promotionsCode[0].appliesTo === EventPromotionAppliesToEnum.PerOrder
      ) {
        const value =
          cur + (totalTicketUpgrade * promotion.discountValue) / 100;
        return value;
      }
      return cur;
    },
    0
  );

  const discountOrder =
    Math.round(
      discountFeeFlatPerOrderAmt || 0 + discountFeePercentPerOrderAmt || 0
    ) || 0;

  let newSubtotal = PaymentUtil.calculatePaymentSubtotalValue({
    tickets: tickets,
    upgrades: upgrades,
  });

  let subtotal = newSubtotal - discountOrder;

  const ticketFeesPromotersPercent = params.fees.filter(
    (f) =>
      f.appliedTo === FeeAppliedToEnum.Ticket &&
      f.appliedBy == FeeAppliedByEnum.Organization &&
      f.type === FeeTypeEnum.Percent
  );


  function getFeeAmount(fee, amt = 0) {
    if (fee.type === FeeTypeEnum.Flat) {
      return fee.value;
    }
    if (fee.type === FeeTypeEnum.Percent) {
      return amt * (fee.value / 100);
    }
  }
  function applyTicketFee(ticket: ICreateOrderTicketParams, fee: IFee): number {
    // Ignore seated fees if not seated
    if (
      fee.filters &&
      fee.filters.includes(FeeFiltersEnum.Seated) &&
      !ticket.seat
    ) {
      return 0;
    }

    const minFee = fee.minAppliedToPrice || 0;
    const maxFee = fee.maxAppliedToPrice || Infinity;
    if (minFee <= ticket.price && ticket.price <= maxFee) {
      if (fee.type === FeeTypeEnum.Flat) {
        return fee.value;
      }
      if (fee.type === FeeTypeEnum.Percent) {
        return (ticket.price * fee.value) / 100;
      }
    } else {
      return 0;
    }
    return 0;
  }

  const promoterOrderFees = params.fees
    .filter(
      (f) =>
        f.appliedTo === FeeAppliedToEnum.Order &&
        f.name != "Sales tax" &&
        f.appliedBy == FeeAppliedByEnum.Organization
    )
    .sort(({ type }) => {
      if (type === "Flat") return -1;
      else return 1;
    });
  const promoterOrderFlatFees = params.fees
    .filter(
      (f) =>
        f.appliedTo === FeeAppliedToEnum.Order &&
        f.name != "Sales tax" &&
        f.appliedBy == FeeAppliedByEnum.Organization
    )
    .sort(({ type }) => {
      if (type === "Percent") return -1;
      else return 1;
    });

  let promoCodeAdd = 0;
  let promoterFeePercentPerOrder11 = 0;

  promoterFees.map((promo) => {
    if (
      promo.type === FeeTypeEnum.Percent &&
      promo.appliedTo === FeeAppliedToEnum.Ticket
    ) {
      const promoterFeePercentPerTicketTotal = tickets.reduce((cur, ticket) => {
        let mainPrice = ticket.price;
        return (
          cur +
          ticketFeesPromotersPercent.reduce((cur, fee) => {
            const value = cur + applyTicketFee(ticket, fee);
            ticket.price = mainPrice;
            return value;
          }, 0)
        );
      }, 0);
      promo.amount = promoterFeePercentPerTicketTotal;
    } else if (
      promo.type === FeeTypeEnum.Percent &&
      promo.appliedTo === FeeAppliedToEnum.Order
    ) {
      promoterFeePercentPerOrder11 = promoterOrderFees.reduce((acc, fee) => {
        if (fee.type == FeeTypeEnum.Percent) {
          return acc + getFeeAmount(fee, subtotal);
        }
        return acc;
      }, 0);

      promo.amount = Math.round(promoterFeePercentPerOrder11);
    } else {
      if (
        promo.appliedTo == FeeAppliedToEnum.Ticket &&
        promo.type === FeeTypeEnum.Flat
      ) {
        let promoterFeeFlatPerTicketTotal = tickets.reduce((cur, ticket) => {
          const value = cur + applyTicketFee(ticket, promo);
          return value;
        }, 0);
        promo.amount = promoterFeeFlatPerTicketTotal;
      } else if (
        promo.appliedTo == FeeAppliedToEnum.Order &&
        promo.type === FeeTypeEnum.Flat
      ) {
        let promoterFeeFlatPerOrder = 0;

        if (promo.type == FeeTypeEnum.Flat) {
          promo.amount = getFeeAmount(promo, subtotal);
          return promo;
        }

        promo.amount = promoterFeeFlatPerOrder;
      }
    }
    promoCodeAdd += Math.round(promo.amount * 100) / 100 || 0;
  });

  const applyUpgradeFee = (upgrade, fee): number => {
    const minFee = fee.minAppliedToPrice || 0;
    const maxFee = fee.maxAppliedToPrice || Infinity;

    if (minFee <= upgrade.price && upgrade.price <= maxFee) {
      if (fee.type === FeeTypeEnum.Flat) {
        return fee.value;
      }
      if (fee.type === FeeTypeEnum.Percent) {
        let percent = (upgrade.price * fee.value) / 100;
        return Math.round(percent * 100) / 100 || 0;
      }
    } else {
      return 0;
    }

    return 0;
  };

  for (let index = 0; index < promoterFees.length; index++) {
    let promoterUpgradeFee = promoterFees[index];
    if (promoterUpgradeFee?.appliedTo === FeeAppliedToEnum.Upgrade) {
      let promoterUpgardeFeeArr = [promoterUpgradeFee];
      const promoterFeeFlatPerUpgardeAmt = params.upgrades.reduce(
        (cur: number, upgrade: any) => {
          return (
            cur +
            promoterUpgardeFeeArr.reduce((cur, fee) => {
              const value = cur + applyUpgradeFee(upgrade, fee);
              return value;
            }, 0)
          );
        },
        0
      );
      promoterUpgradeFee.amount = Math.round(promoterFeeFlatPerUpgardeAmt);
      promoCodeAdd += Math.round(promoterUpgradeFee.amount) || 0;
    }
  }

  const promoterFeeList = promoterFees
    .filter(
      (f) =>
        f.name !== "Sales tax" && f.amount > 0 && f.appliedBy === "Organization"
    )
    .map((f) => ({
      name: f.name,
      amount: f.amount,
    }));
  return promoterFeeList;
};
export default PaymentOverview;
